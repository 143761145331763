import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

function add_financialYear(params) {
  
    const baseUrl = '/add-financial-year';
    return api.create(`${baseUrl}`, params);
    
}
function add_userManagement(params) {
 
    const baseUrl = '/register-user';
    return api.create(`${baseUrl}`, params);
    
}
function dashboardRight_API(params) {
  
    const baseUrl = `/update-dashboard-rights/${params.userid}`;
    return api.update(`${baseUrl}`, params);
    
}
function cashcounterRight_API(params) {
  
    const baseUrl = `/update-cashcounter-rights/${params.userid}`;
    return api.update(`${baseUrl}`, params);
    
}
function roles_API(params) {
  
    const baseUrl = '/add-role';
    return api.create(`${baseUrl}`, params);
    
}
function updateUserApi(params) {
  
    const baseUrl = `update-user/${params.id}`;
    return api.update(`${baseUrl}`, params);
    
}

export {roles_API,add_financialYear,add_userManagement,updateUserApi,dashboardRight_API,cashcounterRight_API};
