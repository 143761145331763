
import { WarrantyType } from './constants';



const INIT_STATE = {
    warrantyRece: null,
    warrantySend: null,
    updWarrantyRece: null,
    updSend: null,
    error: null,
    fault_response: null,
    devicetype_response: null,
    warrantyReturn: null,
    upd_warrantyReturn: null,
    warrantyHandover: null,
    upd_handover: null,
    warrantystatus_response: null,
    loading: false,
    modal_Loading: false,
};




type WarrantyActionType = {
    type:
    | WarrantyType.API_RESPONSE_SUCCESS
    | WarrantyType.API_RESPONSE_ERROR
    | WarrantyType.WARANTY_RECE
    | WarrantyType.UPD_WARANTY_RECE
    | WarrantyType.WARANTY_SEND
    | WarrantyType.UPD_WARANTY_SEND
    | WarrantyType.ADD_FAULT
    | WarrantyType.ADD_DEVICETYPE
    | WarrantyType.WARANTY_RETURN
    | WarrantyType.UPD_WARANTY_RETURN
    | WarrantyType.WARANTY_HANDOVER
    | WarrantyType.UPD_WARANTY_HANDOVER
    | WarrantyType.ADD_WARRANTYSTATUS
    | WarrantyType.RESET;
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    };
};

type State = {
    item?: any;
    loading?: boolean;
    value?: boolean;
};

const Warranty = (state: State = INIT_STATE, action: WarrantyActionType): any => {
    switch (action.type) {
        case WarrantyType.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case WarrantyType.WARANTY_RECE: {
                    return {
                        ...state,
                        warrantyRece: action.payload,
                        loading: false,
                    };
                }

                case WarrantyType.UPD_WARANTY_RECE: {
                    return {
                        ...state,
                        updWarrantyRece: action.payload,
                        loading: false,
                    };
                }
                case WarrantyType.WARANTY_SEND: {
                    return {
                        ...state,
                        warrantySend: action.payload,
                        loading: false,
                    };
                }

                case WarrantyType.UPD_WARANTY_SEND: {
                    return {
                        ...state,
                        updSend: action.payload,
                        loading: false,
                    };
                }
                case WarrantyType.ADD_FAULT: {
                    return {
                        ...state,
                        fault_response: action.payload,
                        modal_Loading: false,
                    };
                }
                case WarrantyType.ADD_DEVICETYPE: {
                    return {
                        ...state,
                        devicetype_response: action.payload,
                        modal_Loading: false,
                    };
                }
                case WarrantyType.ADD_WARRANTYSTATUS: {
                    return {
                        ...state,
                        warrantystatus_response: action.payload,
                        modal_Loading: false,
                    };
                }
                case WarrantyType.WARANTY_RETURN: {
                    return {
                        ...state,
                        warrantyReturn: action.payload,
                        loading: false,
                    };
                }
                case WarrantyType.UPD_WARANTY_RETURN: {
                    return {
                        ...state,
                        upd_warrantyReturn: action.payload,
                        loading: false,
                    };
                }
                case WarrantyType.WARANTY_HANDOVER: {
                    return {
                        ...state,
                        warrantyHandover: action.payload,
                        loading: false,
                    };
                }
                case WarrantyType.UPD_WARANTY_HANDOVER: {
                    return {
                        ...state,
                        upd_handover: action.payload,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case WarrantyType.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {

                case WarrantyType.WARANTY_RECE: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        warrantyRece: null,
                        loading: false,
                    };
                }
                case WarrantyType.UPD_WARANTY_RECE: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        updWarrantyRece: null,
                        loading: false,
                    };
                }
                case WarrantyType.WARANTY_SEND: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        warrantySend: null,
                        loading: false,
                    };
                }
                case WarrantyType.UPD_WARANTY_SEND: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        updSend: null,
                        loading: false,
                    };
                }
                case WarrantyType.WARANTY_RETURN: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        warrantyReturn: null,
                        loading: false,
                    };
                }
                case WarrantyType.UPD_WARANTY_RETURN: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        upd_warrantyReturn: null,
                        loading: false,
                    };
                }
                case WarrantyType.WARANTY_HANDOVER: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        warrantyHandover: null,
                        loading: false,
                    };
                }
                case WarrantyType.UPD_WARANTY_HANDOVER: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        upd_handover: null,
                        loading: false,
                    };
                }
                case WarrantyType.ADD_FAULT: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        fault_response: null,
                        modal_Loading: false,
                    };
                }
                case WarrantyType.ADD_DEVICETYPE: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        devicetype_response: null,
                        modal_Loading: false,
                    };
                }
                case WarrantyType.ADD_WARRANTYSTATUS: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        warrantystatus_response: null,
                        modal_Loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case WarrantyType.WARANTY_RECE:
            return { ...state, loading: true, warrantyRece: null };
        case WarrantyType.WARANTY_SEND:
            return { ...state, loading: true, warrantySend: null };
        case WarrantyType.UPD_WARANTY_SEND:
            return { ...state, loading: true, updSend: null };
        case WarrantyType.WARANTY_RETURN:
            return { ...state, loading: true, warrantyReturn: null };
        case WarrantyType.UPD_WARANTY_RETURN:
            return { ...state, loading: true, upd_warrantyReturn: null };
        case WarrantyType.WARANTY_HANDOVER:
            return { ...state, loading: true, warrantyHandover: null };
        case WarrantyType.UPD_WARANTY_HANDOVER:
            return { ...state, loading: true, upd_handover: null };
        case WarrantyType.ADD_FAULT:
            return { ...state, modal_Loading: true, fault_response: null };
        case WarrantyType.ADD_DEVICETYPE:
            return { ...state, modal_Loading: true, devicetype_response: null };
        case WarrantyType.UPD_WARANTY_RECE:
            return { ...state, loading: true, updWarrantyRece: null };
        case WarrantyType.ADD_WARRANTYSTATUS:
            return { ...state, modal_Loading: true, warrantystatus_response: null };    
        case WarrantyType.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                updWarrantyRece: null,
                updSend: null,
                warrantySend: null,
                warrantyRece: null,
                fault_response: null,
                warrantyReturn: null,
                upd_warrantyReturn: null,
                warrantyHandover: null,
                upd_handover: null,
                devicetype_response: null,
            };
        default:
            return { ...state };
    }
};

export default Warranty;
