import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// apicore
import { APICore } from '../../helpers/api/apiCore';

// helpers
import {warrantyReturnAPI,
    UPD_warrantyReturnAPI,
    warrantyHandoverAPI,
    UPD_warrantyHandoverAPI,devicetype_API,warrantyReceAPI,UPD_warrantyReceAPI,fault_API,warrantySendAPI,UPD_warrantySendAPI,warrantystatus_API} from '../../helpers';

// actions
import { success, errors } from './actions';

// constants
import { WarrantyType } from './constants';

type warrantyData = {
    payload: {id:string;entrydate:string;customerid:string;name:string;phone:string;address:string;details:string;voucherdetdata:[]};
    type: string;
};
type send_warrantyData = {
    payload: {id:string;entrydate:string;supplierid:string;details:string;voucherdetdata:[]};
    type: string;
};
type return_warrantyData = {
    payload: {id:string;entrydate:string;supplierid:string;paymethod:string,postentry:string;bankid:string,reference:string;amount:string;voucherdetdata:[]};
    type: string;
};
type handover_warrantyData = {
    payload: {id:string;entrydate:string;customerid:string;phone:string;name:string;address:string;paymethod:string,postentry:string;bankid:string,reference:string;amount:string;voucherdetdata:[]};
    type: string;
};
type faultTypes = {
    payload: {fault:string;};
    type: string;
};
type devicetype_TYPE = {
    payload: {type:string;};
    type: string;
};
type warrantystatus_TYPE = {
    payload: {status:string;};
    type: string;
};
const api = new APICore();
function* add_receive({ payload: {entrydate,customerid,details,name,phone,address,voucherdetdata} }: warrantyData): SagaIterator {
    try {
        const response = yield call(warrantyReceAPI, {entrydate,customerid,details,name,phone,address,voucherdetdata});
        const receive = response.data;
        yield put(success(WarrantyType.WARANTY_RECE, receive));
    } catch (error: any) {
        
        yield put(errors(WarrantyType.WARANTY_RECE, error));
    }
}
function* upd_receive({ payload: {id,entrydate,customerid,details,name,phone,address,voucherdetdata} }: warrantyData): SagaIterator {
    try {
        const response = yield call(UPD_warrantyReceAPI, {id,entrydate,customerid,details,name,phone,address,voucherdetdata});
        const receive = response.data;
        yield put(success(WarrantyType.UPD_WARANTY_RECE, receive));
    } catch (error: any) {
        
        yield put(errors(WarrantyType.UPD_WARANTY_RECE, error));
    }
}
function* add_send({ payload: {entrydate,supplierid,details,voucherdetdata} }: send_warrantyData): SagaIterator {
    try {
        const response = yield call(warrantySendAPI, {entrydate,supplierid,details,voucherdetdata});
        const receive = response.data;
        yield put(success(WarrantyType.WARANTY_SEND, receive));
    } catch (error: any) {
        
        yield put(errors(WarrantyType.WARANTY_SEND, error));
    }
}
function* upd_send({ payload: {id,entrydate,supplierid,details,voucherdetdata} }: send_warrantyData): SagaIterator {
    try {
        const response = yield call(UPD_warrantySendAPI, {id,entrydate,supplierid,details,voucherdetdata});
        const receive = response.data;
        yield put(success(WarrantyType.UPD_WARANTY_SEND, receive));
    } catch (error: any) {
        
        yield put(errors(WarrantyType.UPD_WARANTY_SEND, error));
    }
}
function* return_add({ payload: {id,entrydate,supplierid,paymethod,postentry,bankid,reference,amount,voucherdetdata} }: return_warrantyData): SagaIterator {
    try {
        const response = yield call(warrantyReturnAPI, {id,entrydate,supplierid,paymethod,postentry,bankid,reference,amount,voucherdetdata});
        const receive = response.data;
        yield put(success(WarrantyType.WARANTY_RETURN, receive));
    } catch (error: any) {
        
        yield put(errors(WarrantyType.WARANTY_RETURN, error));
    }
}
function* upd_return({ payload: {id,entrydate,supplierid,paymethod,postentry,bankid,reference,amount,voucherdetdata} }: return_warrantyData): SagaIterator {
    try {
        const response = yield call(UPD_warrantyReturnAPI, {id,entrydate,supplierid,paymethod,postentry,bankid,reference,amount,voucherdetdata});
        const receive = response.data;
        yield put(success(WarrantyType.UPD_WARANTY_RETURN, receive));
    } catch (error: any) {
        
        yield put(errors(WarrantyType.UPD_WARANTY_RETURN, error));
    }
}
function* handover({ payload: {id,entrydate,customerid,phone,name,address,paymethod,postentry,bankid,reference,amount,voucherdetdata} }: handover_warrantyData): SagaIterator {
    try {
        const response = yield call(warrantyHandoverAPI, {id,entrydate,customerid,phone,name,address,paymethod,postentry,bankid,reference,amount,voucherdetdata});
        const receive = response.data;
        yield put(success(WarrantyType.WARANTY_HANDOVER, receive));
    } catch (error: any) {
        
        yield put(errors(WarrantyType.WARANTY_HANDOVER, error));
    }
}
function* upd_handover({ payload: {id,entrydate,customerid,phone,name,address,paymethod,postentry,bankid,reference,amount,voucherdetdata} }: handover_warrantyData): SagaIterator {
    try {
        const response = yield call(UPD_warrantyHandoverAPI, {id,entrydate,customerid,phone,name,address,paymethod,postentry,bankid,reference,amount,voucherdetdata});
        const receive = response.data;
        yield put(success(WarrantyType.UPD_WARANTY_HANDOVER, receive));
    } catch (error: any) {
        
        yield put(errors(WarrantyType.UPD_WARANTY_HANDOVER, error));
    }
}
function* manage_fault({ payload: {fault} }: faultTypes): SagaIterator {
    try {
        const response = yield call(fault_API, {fault});
        const fautls = response.data;
        yield put(success(WarrantyType.ADD_FAULT, fautls));
    } catch (error: any) {
        
        yield put(errors(WarrantyType.ADD_FAULT, error));
    }
}
function* manage_devicetype({ payload: {type} }: devicetype_TYPE): SagaIterator {
    try {
        const response = yield call(devicetype_API, {type});
        const devicetypes = response.data;
        yield put(success(WarrantyType.ADD_DEVICETYPE, devicetypes));
    } catch (error: any) {
        
        yield put(errors(WarrantyType.ADD_DEVICETYPE, error));
    }
}

function* manage_warrantystatus({ payload: {status} }: warrantystatus_TYPE): SagaIterator {
    try {
        const response = yield call(warrantystatus_API, {status});
        const warrantystatus = response.data;
        yield put(success(WarrantyType.ADD_WARRANTYSTATUS, warrantystatus));
    } catch (error: any) {
        
        yield put(errors(WarrantyType.ADD_WARRANTYSTATUS, error));
    }
} 


export function* watchAdd(): any {
    yield takeEvery(WarrantyType.WARANTY_RECE, add_receive);
    yield takeEvery(WarrantyType.UPD_WARANTY_RECE, upd_receive);
    yield takeEvery(WarrantyType.ADD_FAULT, manage_fault);
    yield takeEvery(WarrantyType.ADD_DEVICETYPE, manage_devicetype);
    yield takeEvery(WarrantyType.ADD_WARRANTYSTATUS, manage_warrantystatus);
    yield takeEvery(WarrantyType.WARANTY_SEND, add_send);
    yield takeEvery(WarrantyType.UPD_WARANTY_SEND,upd_send);
    yield takeEvery(WarrantyType.WARANTY_RETURN,return_add);
    yield takeEvery(WarrantyType.UPD_WARANTY_RETURN,upd_return);
    yield takeEvery(WarrantyType.WARANTY_HANDOVER,handover);
    yield takeEvery(WarrantyType.UPD_WARANTY_HANDOVER,upd_handover);
}

function* warrantySaga() {
    yield all([fork(watchAdd)]);
}

export default warrantySaga;
