
import { ItmmngtActionTypes } from './constants';



const INIT_STATE = {
    itemAdded: null,
    itemColor: null,
    itemUpdated: null,
    itemUnitAdd: null,
    itembrandAdded: null,
    itemcodeAdded: null,
    itemTypeAdded: null,
    itemcategoryAdded: null,
    multipleitems: null,
    itemSubcategory: null,
    UPDmultipleitems: null,
    itemcategoryUpdated: null,
    manFormLoc: null,
    openingstockadded: null,
    openingstockupd: null,
    loading: false,
    ModalLoading: false,
    stockadjustmentadded: null,
    addStockTrans: null,
    updStockTrans: null,
    stockadjustmentupdated: null,
    stockinadded: null,
    stockinupdated: null,
    stockoutadded: null,
    stockoutupdated: null,

};

type ItemData = {
    id:string; itemcode: string; description: string; UrduDescription:string; itemtype:string; categoryid:string; brandid:string; masterpackingunitid:string;  unitprice:string; price1:string; itemcosteach:string; notes:string;minstocklevel:string;codetype:string;
};
type UnitData = {
    unitname:string; 
};
type BrandData = {
    name:string; 
};
type ColorData = {
    name:string; 
};
type CategoryData = {
    name:string; 
};


type ItmmngtActionType = {
    type:
        | ItmmngtActionTypes.API_RESPONSE_SUCCESS
        | ItmmngtActionTypes.API_RESPONSE_ERROR
        | ItmmngtActionTypes.ADD_ITEM
        | ItmmngtActionTypes.ADD_ITEMUNIT
        | ItmmngtActionTypes.ADD_ITEMBRAND
        | ItmmngtActionTypes.ADD_ITEMCODE
        | ItmmngtActionTypes.ADD_OS      
        | ItmmngtActionTypes.ADD_ITEMTYPE
        | ItmmngtActionTypes.ADD_ITEMCAT
        | ItmmngtActionTypes.EDIT_ITEMCAT
        | ItmmngtActionTypes.EDIT_ITEM
        | ItmmngtActionTypes.MULTIPLE_ITEMS_UPD
        | ItmmngtActionTypes.FORM_LOC
        | ItmmngtActionTypes.MULTIPLE_ITEMS 
        | ItmmngtActionTypes.UPD_OS 
        | ItmmngtActionTypes.ADD_ITEMSUBCAT 
        | ItmmngtActionTypes.RESET
        | ItmmngtActionTypes.ADD_ADJUSTMENT
        | ItmmngtActionTypes.ADD_TRANSFER
        | ItmmngtActionTypes.UPD_TRANSFER
        | ItmmngtActionTypes.UPD_ADJUSTMENT
        | ItmmngtActionTypes.ADD_STOCKIN
        | ItmmngtActionTypes.UPD_STOCKIN
        | ItmmngtActionTypes.ADD_STOCKOUT
        | ItmmngtActionTypes.ADD_COLOR
        | ItmmngtActionTypes.UPD_STOCKOUT;
    payload: {
        actionType?: string;
        data?: ItemData | {};
        error?: string;
    };
};

type State = {
    item?: ItemData | {};
    loading?: boolean;
    value?: boolean;
};

const Itmmngt = (state: State = INIT_STATE, action: ItmmngtActionType): any => {
    switch (action.type) {
        case ItmmngtActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ItmmngtActionTypes.ADD_ITEM: {
                    return {
                        ...state,
                        itemAdded: action.payload,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.ADD_COLOR: {
                    return {
                        ...state,
                        itemColor: action.payload,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.EDIT_ITEM: {
                    return {
                        ...state,
                        itemUpdated: action.payload,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.MULTIPLE_ITEMS : {
                    return {
                        ...state,
                        multipleitems: action.payload,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.MULTIPLE_ITEMS_UPD : {
                    return {
                        ...state,
                        UPDmultipleitems: action.payload,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.ADD_ITEMUNIT: {
                    return {
                        ...state,
                        itemUnitAdd: action.payload,
                        ModalLoading: false,
                    };
                }
                case ItmmngtActionTypes.ADD_ITEMBRAND: {
                        return {
                            ...state,
                            itembrandAdded: action.payload,
                            ModalLoading: false,
                        };
                }
                
                case ItmmngtActionTypes.ADD_ITEMCODE: {
                        return {
                            ...state,
                            itemcodeAdded: action.payload,
                            ModalLoading: false,
                        };
                }
              
                case ItmmngtActionTypes.ADD_ITEMTYPE: {
                    return {
                        ...state,
                        itemTypeAdded: action.payload,
                        ModalLoading: false,
                    };
                }
                case ItmmngtActionTypes.ADD_ITEMCAT: {
                    return {
                        ...state,
                        itemcategoryAdded: action.payload,
                        ModalLoading: false,
                    };
                }
                case ItmmngtActionTypes.ADD_ITEMSUBCAT: {
                    return {
                        ...state,
                        itemSubcategory: action.payload,
                        ModalLoading: false,
                    };
                }
                case ItmmngtActionTypes.EDIT_ITEMCAT: {
                    return {
                        ...state,
                        itemcategoryUpdated: action.payload,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.FORM_LOC: {
                    return {
                        ...state,
                        manFormLoc: action.payload,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.ADD_OS: {
                    return {
                        ...state,
                        openingstockadded: action.payload,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.ADD_ADJUSTMENT: {
                    return {
                        ...state,
                        stockadjustmentadded: action.payload,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.UPD_ADJUSTMENT: {
                    return {
                        ...state,
                        stockadjustmentupdated: action.payload,
                        loading: false,
                    };
                }
                
                case ItmmngtActionTypes.ADD_STOCKIN: {
                    return {
                        ...state,
                        stockinadded: action.payload,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.UPD_STOCKIN: {
                    return {
                        ...state,
                        stockinupdated: action.payload,
                        loading: false,
                    };
                }
                
                case ItmmngtActionTypes.ADD_STOCKOUT: {
                    return {
                        ...state,
                        stockoutadded: action.payload,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.UPD_STOCKOUT: {
                    return {
                        ...state,
                        stockoutupdated: action.payload,
                        loading: false,
                    };
                }


                case ItmmngtActionTypes.ADD_TRANSFER: {
                    return {
                        ...state,
                        addStockTrans: action.payload,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.UPD_TRANSFER: {
                    return {
                        ...state,
                        updStockTrans: action.payload,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.UPD_OS: {
                    return {
                        ...state,
                        openingstockupd: action.payload,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
                }

        case ItmmngtActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                
                case ItmmngtActionTypes.ADD_ITEM: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        itemAdded: null,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.ADD_COLOR: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        itemColor: null,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.EDIT_ITEM: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        itemUpdated: null,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.ADD_ITEMUNIT: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        itemUnitAdd: null,
                        ModalLoading: false,
                    };
                }
                case ItmmngtActionTypes.ADD_ITEMBRAND: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        itembrandAdded: null,
                        ModalLoading: false,
                    };
                }
                case ItmmngtActionTypes.MULTIPLE_ITEMS : {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        multipleitems: null,
                        loading: false,
                    };
                }
                
                case ItmmngtActionTypes.ADD_ITEMCODE: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        itemcodeAdded: null,
                        ModalLoading: false,
                    };
                }
                
                case ItmmngtActionTypes.ADD_ITEMTYPE: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        itemTypeAdded: null,
                        ModalLoading: false,
                    };
                }
                case ItmmngtActionTypes.ADD_ITEMCAT: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        itemcategoryAdded: null,
                        ModalLoading: false,
                    };
                }
                case ItmmngtActionTypes.ADD_ITEMSUBCAT: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        itemSubcategory: null,
                        ModalLoading: false,
                    };
                }
                case ItmmngtActionTypes.FORM_LOC: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        manFormLoc: null,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.EDIT_ITEMCAT: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        itemcategoryUpdated: null,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.ADD_OS: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        openingstockadded: null,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.ADD_ADJUSTMENT: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        stockadjustmentadded: null,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.UPD_ADJUSTMENT: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        stockadjustmentupdated: null,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.ADD_STOCKIN: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        stockinadded: null,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.UPD_STOCKIN: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        stockinupdated: null,
                        loading: false,
                    };
                }

                case ItmmngtActionTypes.ADD_STOCKOUT: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        stockoutadded: null,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.UPD_STOCKOUT: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        stockoutupdated: null,
                        loading: false,
                    };
                }


                case ItmmngtActionTypes.ADD_TRANSFER: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addStockTrans: null,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.UPD_TRANSFER: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        updStockTrans: null,
                        loading: false,
                    };
                }
                
                case ItmmngtActionTypes.UPD_OS : {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        openingstockupd: null,
                        loading: false,
                    };
                }
                case ItmmngtActionTypes.MULTIPLE_ITEMS_UPD : {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        UPDmultipleitems: null,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ItmmngtActionTypes.ADD_ITEM:
            return { ...state, loading: true, itemAdded: null };
        case ItmmngtActionTypes.EDIT_ITEM:
            return { ...state, loading: true, itemUpdated: null };
        case ItmmngtActionTypes.ADD_ITEMUNIT:
                return { ...state, ModalLoading: true, itemUnitAdd: null };
        case ItmmngtActionTypes.ADD_ITEMBRAND:
                return { ...state, ModalLoading: true, itembrandAdded: null };
       case ItmmngtActionTypes.ADD_ITEMCODE:
                return { ...state, ModalLoading: true, itemcodeAdded: null }; 
        case ItmmngtActionTypes.ADD_ITEMTYPE:
                return { ...state, ModalLoading: true, itemTypeAdded: null };
        case ItmmngtActionTypes.ADD_ITEMCAT:
                return { ...state, ModalLoading: true, itemcategoryAdded: null };
        case ItmmngtActionTypes.ADD_ITEMSUBCAT:
                return { ...state, ModalLoading: true, itemSubcategory: null };
        case ItmmngtActionTypes.FORM_LOC:
                return { ...state, loading: true, manFormLoc: null };
         case ItmmngtActionTypes.EDIT_ITEMCAT:
                return { ...state, loading: true, itemcategoryUpdated: null };
         case ItmmngtActionTypes.UPD_OS:
                return { ...state, loading: true, openingstockupd: null };
         case ItmmngtActionTypes.ADD_OS:
                return { ...state, loading: true, openingstockadded: null };
        case ItmmngtActionTypes.ADD_ADJUSTMENT:
                return { ...state, loading: true, stockadjustmentadded: null };
        case ItmmngtActionTypes.UPD_ADJUSTMENT:
                return { ...state, loading: true, stockadjustmentupdated: null };
        case ItmmngtActionTypes.ADD_STOCKIN:
                return { ...state, loading: true, stockinadded: null };
        case ItmmngtActionTypes.UPD_STOCKIN:
                return { ...state, loading: true, stockinupdated: null };        
        case ItmmngtActionTypes.ADD_STOCKOUT:
                return { ...state, loading: true, stockoutadded: null };
        case ItmmngtActionTypes.UPD_STOCKOUT:
                return { ...state, loading: true, stockoutupdated: null };        
        case ItmmngtActionTypes.ADD_TRANSFER:
                return { ...state, loading: true, addStockTrans: null };
        case ItmmngtActionTypes.UPD_TRANSFER:
                return { ...state, loading: true, updStockTrans: null };        
         case ItmmngtActionTypes.MULTIPLE_ITEMS :
                return { ...state, loading: true, multipleitems: null };
         case ItmmngtActionTypes.MULTIPLE_ITEMS_UPD :
                return { ...state, loading: true, UPDmultipleitems: null };
         case ItmmngtActionTypes.ADD_COLOR :
                return { ...state, loading: true, itemColor: null };
        case ItmmngtActionTypes.RESET:
            return {
                ...state,
                loading: false,
                ModalLoading: false,
                error: false,
                itemAdded: null,
                itemUpdated: null,
                itemUnitAdd: null,
                itembrandAdded: null,
                itemvarietyAdded: null,
                itemTypeAdded: null,
                itemcategoryAdded: null,
                multipleitems: null,
                UPDmultipleitems: null,
                itemSubcategory: null,
                manFormLoc: null,
                itemcategoryUpdated: null,
                openingstockupd: null,
                openingstockadded: null,
                stockadjustmentadded: null,
                stockadjustmentupdated:null,
                addStockTrans: null,
                updStockTrans:null,
                itemcodeAdded: null,
                stockinadded: null,
                stockinupdated:null,
                stockoutadded: null,
                itemColor: null,
                stockoutupdated:null,
             };
        default:
            return { ...state };
    }
};

export default Itmmngt;
