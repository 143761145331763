export enum SalesActionTypes {
    API_RESPONSE_SUCCESS = '@@sales/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@sales/API_RESPONSE_ERROR',
    ADD_Inv = '@@sales/ADD_Inv',
    ADD_POS = '@@sales/ADD_POS',
    UPD_POS = '@@sales/UPD_POS',
    ADD_SALEINVOICE = '@@sales/ADD_SALEINVOICE',
    UPD_SALEINVOICE = '@@sales/UPD_SALEINVOICE',
    ADD_INVGRID = '@@sales/ADD_INVGRID',
    PAYMENT_RECEIPT = '@@sales/PAYMENT_RECEIPT',
    ADD_INVOICERETURN = '@@sales/ADD_INVOICERETURN',
    UPD_INVOICERETURN = '@@sales/UPD_INVOICERETURN',
    UPD_PAYMENT_RECEIPT = '@@sales/UPD_PAYMENT_RECEIPT',
    RESET = '@@sales/RESET',
}