import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

function addCust(params: { name: string;urduName:string; cellphone: string; cellphonecomment: string; cnic:string; address1:string; cityid:string; country:string; entry_firstname:string; middle_name:string; entry_lastname:string; phone1: string; phone1comment: string; phone2: string; phone2comment: string;  email1: string; email1comment: string; email2: string; email2comment: string;   groupid:string;  salescategoryid:string; salesmanid:string; invoicetermsid:string; combineSupplierId:string; crlimit:string; discount:string; notes:string;disablecrlimit:string;cellphonecode:string;phone1code:string; }) {
  
    const baseUrl = '/add-customer';
    return api.create(`${baseUrl}`, params);
    
}
function updCust(params: {id:string;name: string;urduName:string; cellphone: string; cellphonecomment: string; cnic:string; address1:string; cityid:string; country:string; entry_firstname:string; middle_name:string; entry_lastname:string; phone1: string; phone1comment: string; phone2: string; phone2comment: string;  email1: string; email1comment: string; email2: string; email2comment: string;   groupid:string;  salescategoryid:string; salesmanid:string; invoicetermsid:string; combineSupplierId:string; crlimit:string; discount:string; notes:string;disablecrlimit:string;cellphonecode:string;phone1code:string; }) {
  
  const baseUrl = `/update-customer/`+params.id;
  return api.update(`${baseUrl}`, params);
   
}
function cusGroup(params: {groupname :string}) {
  
  const baseUrl = `/add-customer-group`;
  return api.create(`${baseUrl}`, params);
}
function salesPerson(params: {name: string;address1: string;phone1: string;city: string;}) {
  
  const baseUrl = `/add-saleman`;
  return api.create(`${baseUrl}`, params);
}
function saleCategorys(params: {name :string}) {
  
  const baseUrl = `/add-customer-category`;
  return api.create(`${baseUrl}`, params);
}
function multi_customers(params) {
  const baseUrl = `/add-multiple-customers`;
  return api.create(`${baseUrl}`, params);
}
function manageCarriers(params: {name :string}) {
  
  const baseUrl = `/add-carrier`;
  return api.create(`${baseUrl}`, params);
}
function cashcounter_API(params: {description :string;cashaccountid:string}) {
  const baseUrl = `/add-cashcounter`;
  return api.create(`${baseUrl}`, params);
}
function termsIds(params: {verbal :string}) {
  
  const baseUrl = `/add-invoice-term`;
  return api.create(`${baseUrl}`, params);
}
function updmultipleCust_api(params) {
  const baseUrl = `/multiple-customers-update/`;
  return api.update(`${baseUrl}${params.id}`, params);
}
const Cust_openBalance = (params:{entrydate: string;rowisedata: Object;})=>{
    return  api.create('/add-customer-balance',params)
}
const upd_CustBalance = (params:{id:string;entrydate: string;rowisedata: Object;})=>{
    return  api.update('/update-customer-balance/'+params.id,params)
}
axios.interceptors.response.use(
  (response) => {
      return response;
  },
);
export {updmultipleCust_api, multi_customers,addCust, updCust,cusGroup,salesPerson,saleCategorys ,termsIds,manageCarriers,Cust_openBalance,upd_CustBalance,cashcounter_API};
