import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// apicore
import { APICore } from '../../helpers/api/apiCore';

import Swal from 'sweetalert2';
// helpers
import {
    addCust as addApi,
    updCust as updApi,
    cusGroup as custGroupApi,
    salesPerson  as salesPersonApi,
    termsIds  as termIdApi,
    saleCategorys  as saleCategoryApi,
    Cust_openBalance  as openBalances,
    upd_CustBalance  as updBalances,manageCarriers,multi_customers,updmultipleCust_api,cashcounter_API
} from '../../helpers';

// actions
import { customerApiResponseSuccess, customerApiResponseError } from './actions';

// constants
import { CustomerActionTypes } from './constants';

type CustData = {
    payload: {
        id:string; name: string;urduName:string; cellphone: string; cellphonecomment: string; cnic:string; address1:string; cityid:string; country:string; entry_firstname:string; middle_name:string; entry_lastname:string; phone1: string; phone1comment: string; phone2: string; phone2comment: string;  email1: string; email1comment: string; email2: string; email2comment: string;   groupid:string;  salescategoryid:string; salesmanid:string; invoicetermsid:string; combineSupplierId:string; crlimit:string; discount:string; notes:string;disablecrlimit:string;cellphonecode:string;phone1code:string;
    };
    type: string;
};
type updateCustData = {
    payload: {
        id:string; name: string;urduName:string; cellphone: string; cellphonecomment: string; cnic:string; address1:string; cityid:string; country:string; entry_firstname:string; middle_name:string; entry_lastname:string; phone1: string; phone1comment: string; phone2: string; phone2comment: string;  email1: string; email1comment: string; email2: string; email2comment: string;   groupid:string;  salescategoryid:string; salesmanid:string; invoicetermsid:string; combineSupplierId:string; crlimit:string; discount:string; notes:string;disablecrlimit:string;cellphonecode:string;phone1code:string;
    };
    type: string;
};
type multiCustUPD = {
    payload: {data_array:object;id:object};
    type: object;
};
type cashcounter_type = {
    payload: {description:string;cashaccountid:string;};
    type: object;
};
type CustGroupData = {
    payload: {
        groupname:string; 
    };
    type: string;
};
type SaleManData = {
    payload: {
        name: string;address1: string;phone1: string;city: string;
    };
    type: string;
};
type balanceType = {
    payload: {
        entrydate: string;rowisedata: Object;
    };
    type: string;
};
type upd_balanceType = {
    payload: {
        id:string;entrydate: string;rowisedata: Object;
    };
    type: string;
};
type idData = {
    payload: {
        verbal:string; 
    };
    type: string;
};
type customerType = {
    payload: {data_array:object};
    type: object;
};

const api = new APICore();
// const [res , setRes] = useState()
export const setResponse = {
    newResponse:null
}
export const saleCatRes = {
    newResponse:null
}
export const custGroupRes = {
    newResponse:null
}
export const salesPersonRes = {
    newResponse:null
}
export const setUpdResponse = {
    newResponse:null
}
export const idResponse = {
    newResponse:null
}

function* add({ payload: {name,urduName, cellphone, cellphonecomment, cnic, address1, cityid, country, entry_firstname, middle_name, entry_lastname, phone1, phone1comment, phone2, phone2comment,  email1, email1comment, email2, email2comment,   groupid,  salescategoryid, salesmanid, invoicetermsid, combineSupplierId, crlimit, discount, notes,disablecrlimit,cellphonecode,phone1code } }: CustData): SagaIterator {
    try {
        const response = yield call(addApi, {name, urduName,cellphone, cellphonecomment, cnic, address1, cityid, country, entry_firstname, middle_name, entry_lastname, phone1, phone1comment, phone2, phone2comment,  email1, email1comment, email2, email2comment,   groupid,  salescategoryid, salesmanid, invoicetermsid, combineSupplierId, crlimit, discount, notes,disablecrlimit,cellphonecode,phone1code });
        
        setResponse['newResponse'] = response;
        const item = response.data;
        yield put(customerApiResponseSuccess(CustomerActionTypes.ADD_CUST, item));
       
    } catch (error: any) {
        yield put(customerApiResponseError(CustomerActionTypes.ADD_CUST, error));
        
            }
}
function* upd({ payload: { id, name, urduName,cellphone, cellphonecomment, cnic, address1, cityid, country, entry_firstname, middle_name, entry_lastname, phone1, phone1comment, phone2, phone2comment,  email1, email1comment, email2, email2comment,   groupid,  salescategoryid, salesmanid, invoicetermsid, combineSupplierId, crlimit, discount, notes,disablecrlimit,cellphonecode,phone1code } }: updateCustData): SagaIterator {
    try {
        const response = yield call(updApi, {id, name, urduName,cellphone, cellphonecomment, cnic, address1, cityid, country, entry_firstname, middle_name, entry_lastname, phone1, phone1comment, phone2, phone2comment,  email1, email1comment, email2, email2comment,   groupid,  salescategoryid, salesmanid, invoicetermsid, combineSupplierId, crlimit, discount, notes, disablecrlimit,cellphonecode,phone1code });
        
        setUpdResponse['newResponse'] = response;
        const item = response.data;
        yield put(customerApiResponseSuccess(CustomerActionTypes.UPD_CUST, item));
       
    } catch (error: any) {
        yield put(customerApiResponseError(CustomerActionTypes.UPD_CUST, error));
        
            }
}

function* saleCategoryAdd({payload:{name}}: SaleManData):  SagaIterator{
    try{
        const response = yield call(saleCategoryApi,{name})
        
        saleCatRes['newResponse'] = response;
        const item = response.data
        yield put(customerApiResponseSuccess(CustomerActionTypes.SALE_CAT,item))
       
    }catch (error: any) {
       yield put(customerApiResponseError(CustomerActionTypes.SALE_CAT, error)); 
       
    }
}
function* multipleCustomers({payload:{data_array}}: customerType):  SagaIterator{
    try{
        const response = yield call(multi_customers,{data_array})
        const item = response.data
        yield put(customerApiResponseSuccess(CustomerActionTypes.MULTIPLE_CUSTOMERS,item))
       
    }catch (error: any) {
       yield put(customerApiResponseError(CustomerActionTypes.MULTIPLE_CUSTOMERS, error)); 
       
    }
}
 function* updMultiple({ payload: {data_array,id} }:multiCustUPD): SagaIterator {
            try {
                const response = yield call(updmultipleCust_api, {data_array,id});
                const item = response.data;
                yield put(customerApiResponseSuccess(CustomerActionTypes.MULTIPLE_CUSTOMERS_UPD, item));
            } catch (error: any) {
                yield put(customerApiResponseError(CustomerActionTypes.MULTIPLE_CUSTOMERS_UPD, error));
                    }
            }
function* carrier_Add({payload:{name}}: SaleManData):  SagaIterator{
    try{
        const response = yield call(manageCarriers,{name})
        const item = response.data
        yield put(customerApiResponseSuccess(CustomerActionTypes.ADD_CARRIER,item))
       
    }catch (error: any) {
       yield put(customerApiResponseError(CustomerActionTypes.ADD_CARRIER, error)); 
       
    }
}
function* cashcounter_Add({payload:{description,cashaccountid}}: cashcounter_type):  SagaIterator{
    try{
        const response = yield call(cashcounter_API,{description,cashaccountid})
        const item = response.data
        yield put(customerApiResponseSuccess(CustomerActionTypes.ADD_CASHCOUNTER,item))
       
    }catch (error: any) {
       yield put(customerApiResponseError(CustomerActionTypes.ADD_CASHCOUNTER, error)); 
       
    }
}
function* custGroup({payload:{groupname}}: CustGroupData):  SagaIterator{
    try{
        const response = yield call(custGroupApi,{groupname})
        
        custGroupRes['newResponse'] = response;
        const item = response.data
        yield put(customerApiResponseSuccess(CustomerActionTypes.CUST_GROUP,item))
       
    }catch (error: any) {
       yield put(customerApiResponseError(CustomerActionTypes.CUST_GROUP, error)); 
       
    }
}
function* salePerson({payload:{name,address1,phone1,city}}: SaleManData):  SagaIterator{
    try{
        const response = yield call(salesPersonApi,{name,address1,phone1,city})
        
        salesPersonRes['newResponse'] = response;
        const item = response.data
        yield put(customerApiResponseSuccess(CustomerActionTypes.SALE_MAN,item))
       
    }catch (error: any) {
       yield put(customerApiResponseError(CustomerActionTypes.SALE_MAN, error)); 
       
    }
}
function* InvoiceTermId({payload:{verbal}}: idData):  SagaIterator{
    try{
        const response = yield call(termIdApi,{verbal})
        
        idResponse['newResponse'] = response;
        const item = response.data
        yield put(customerApiResponseSuccess(CustomerActionTypes.TERM_ID,item))
       
    }catch (error: any) {
       yield put(customerApiResponseError(CustomerActionTypes.TERM_ID, error)); 
       
    }
}
function* addBalance ({payload:{entrydate,rowisedata}}:balanceType):  SagaIterator{
    try{
        const response = yield call(openBalances,{entrydate,rowisedata})
        const balance = response.data;
        yield put(customerApiResponseSuccess(CustomerActionTypes.OPEN_BALANCE,balance))
    }catch(error: any) {
        yield put(customerApiResponseError(CustomerActionTypes.OPEN_BALANCE, error)); 
    }
}
function* updBalance ({payload:{id,entrydate,rowisedata}}:upd_balanceType):  SagaIterator{
    try{
        const response = yield call(updBalances,{id,entrydate,rowisedata})
        const balance = response.data;
        yield put(customerApiResponseSuccess(CustomerActionTypes.UPD_BALANCE,balance))
    }catch(error: any) {
        yield put(customerApiResponseError(CustomerActionTypes.UPD_BALANCE, error)); 
    }
}

export function* watchAdd(): any {
    yield takeEvery(CustomerActionTypes.ADD_CUST, add);
}
export function* watchUpd(): any {
    yield takeEvery(CustomerActionTypes.UPD_CUST, upd);
}
export function* watchCustGroup(): any {
    yield takeEvery(CustomerActionTypes.CUST_GROUP, custGroup);
}
export function* watchSalePerson(): any {
    yield takeEvery(CustomerActionTypes.SALE_MAN, salePerson);
}
export function* watchSaleCategory(): any {
    yield takeEvery(CustomerActionTypes.SALE_CAT, saleCategoryAdd);
}
export function* watchCarriers(): any {
    yield takeEvery(CustomerActionTypes.ADD_CARRIER,carrier_Add);
}
export function* watchTermId(): any {
    yield takeEvery(CustomerActionTypes.TERM_ID, InvoiceTermId);
}
export function* watchOpenBalance():any{
    yield takeEvery(CustomerActionTypes.OPEN_BALANCE,addBalance)
}
export function* watchUpdBalance():any{
    yield takeEvery(CustomerActionTypes.UPD_BALANCE,updBalance)
}
export function* watchMultiCust():any{
    yield takeEvery(CustomerActionTypes.MULTIPLE_CUSTOMERS,multipleCustomers)
}
export function* watchUpdMultiple(): any {
    yield takeEvery(CustomerActionTypes.MULTIPLE_CUSTOMERS_UPD, updMultiple);
}
export function* watchCashCount(): any {
    yield takeEvery(CustomerActionTypes.ADD_CASHCOUNTER, cashcounter_Add);
}

function* customerSaga() {
    yield all([fork(watchAdd)]);
    yield all([fork(watchUpd)]);
    yield all([fork(watchCustGroup)]);
    yield all([fork(watchSalePerson)]);
    yield all([fork(watchSaleCategory)]);
    yield all([fork(watchCarriers)]);
    yield all([fork(watchTermId)]);
    yield all([fork(watchMultiCust)]);
    yield all([fork(watchOpenBalance)]);
    yield all([fork(watchUpdMultiple)]);
    yield all([fork(watchUpdBalance)]);
    yield all([fork(watchCashCount)]);
}

export default customerSaga;
