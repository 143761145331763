import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();
// let users = api.getLoggedInUser();
function addSupplier(params) {
    const baseUrl = '/add-supplier';
    return api.create(`${baseUrl}`, params);
    
}
function updaSupplier(params) {
  
    const baseUrl = '/update-supplier/'+params.id;
    return api.create(`${baseUrl}`, params);
    
}
function supplierGroupApi(params) {
  
    const baseUrl = '/add-supplier-group';
    return api.create(`${baseUrl}`, params);
    
}
function multiSupplier_add(params) {
    const baseUrl = '/add-multiple-suppliers';
    return api.create(`${baseUrl}`, params);
    
}
function multiSupplier_upd(params) {
    const baseUrl = '/update-multiple-suppliers/';
    return api.update(`${baseUrl}${params.id}`, params);
    
}
function openVenBalances(params) {
    const baseUrl = '/add-supplier-balance';
    return api.create(`${baseUrl}`, params);
    
}





export { openVenBalances,addSupplier,supplierGroupApi,updaSupplier,multiSupplier_add,multiSupplier_upd};
