// constants
import { WarrantyType } from './constants';

export type WarrantyActionType = {
    type:
        | WarrantyType.API_RESPONSE_SUCCESS
        | WarrantyType.API_RESPONSE_ERROR
        | WarrantyType.RESET
        | WarrantyType.WARANTY_RECE
        | WarrantyType.WARANTY_SEND
        | WarrantyType.ADD_DEVICETYPE
        | WarrantyType.UPD_WARANTY_SEND
        | WarrantyType.ADD_FAULT
        | WarrantyType.WARANTY_RETURN
        | WarrantyType.UPD_WARANTY_RETURN
        | WarrantyType.WARANTY_HANDOVER
        | WarrantyType.UPD_WARANTY_HANDOVER
        | WarrantyType.UPD_WARANTY_RECE
        | WarrantyType.ADD_WARRANTYSTATUS;
    payload: {} | string;
};

export const success = (actionType: string, data:any): WarrantyActionType => ({
    type: WarrantyType.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const errors = (actionType: string, error: string): WarrantyActionType => ({
    type: WarrantyType.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const addWarrantyReceive = (entrydate,customerid,details,name,phone,address,voucherdetdata): WarrantyActionType => ({
    type: WarrantyType.WARANTY_RECE,
    payload: {entrydate,customerid,details,name,phone,address,voucherdetdata},
});
export const updWarrantyReceive = (id,entrydate,customerid,details,name,phone,address,voucherdetdata): WarrantyActionType => ({
    type: WarrantyType.UPD_WARANTY_RECE,
    payload: {id,entrydate,customerid,details,name,phone,address,voucherdetdata},
});
export const addWarrantySend = (entrydate,supplierid,details,voucherdetdata): WarrantyActionType => ({
    type: WarrantyType.WARANTY_SEND,
    payload: {entrydate,supplierid,details,voucherdetdata},
});
export const updWarrantySend = (id,entrydate,supplierid,details,voucherdetdata): WarrantyActionType => ({
    type: WarrantyType.UPD_WARANTY_SEND,
    payload: {id,entrydate,supplierid,details,voucherdetdata},
});
export const addWarrantyReturn = (entrydate,supplierid,paymethod,postentry,bankid,reference,amount,voucherdetdata): WarrantyActionType => ({
    type: WarrantyType.WARANTY_RETURN,
    payload: {entrydate,supplierid,paymethod,postentry,bankid,reference,amount,voucherdetdata},
});
export const updWarrantyReturn = (id,entrydate,supplierid,paymethod,postentry,bankid,reference,amount,voucherdetdata): WarrantyActionType => ({
    type: WarrantyType.UPD_WARANTY_RETURN,
    payload: {id,entrydate,supplierid,paymethod,postentry,bankid,reference,amount,voucherdetdata},
});
export const addWarrantyHandover = (entrydate,customerid,phone,name,address,paymethod,postentry,bankid,reference,amount,voucherdetdata): WarrantyActionType => ({
    type: WarrantyType.WARANTY_HANDOVER,
    payload: {entrydate,customerid,phone,name,address,paymethod,postentry,bankid,reference,amount,voucherdetdata},
});
export const updWarrantyHandover = (id,entrydate,customerid,phone,name,address,paymethod,postentry,bankid,reference,amount,voucherdetdata): WarrantyActionType => ({
    type: WarrantyType.UPD_WARANTY_HANDOVER,
    payload: {id,entrydate,customerid,phone,name,address,paymethod,postentry,bankid,reference,amount,voucherdetdata},
});

export const manageFault = (fault): WarrantyActionType => ({
    type: WarrantyType.ADD_FAULT,
    payload: {fault},
});
export const manageDeviceType = (type): WarrantyActionType => ({
    type: WarrantyType.ADD_DEVICETYPE,
    payload: {type},
});
export const manageWarrantyStatus = (status): WarrantyActionType => ({
    type: WarrantyType.ADD_WARRANTYSTATUS,
    payload: {status},
});
export const resetWarranty = (): WarrantyActionType => ({
    type: WarrantyType.RESET,
    payload: {},
});

