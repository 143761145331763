export enum WarrantyType {
    API_RESPONSE_SUCCESS = '@@warranty/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@warranty/API_RESPONSE_ERROR',
    WARANTY_RECE = '@@warranty/WARANTY_RECE',
    ADD_FAULT = '@@warranty/ADD_FAULT',
    UPD_WARANTY_RECE = '@@warranty/UPD_WARANTY_RECE',
    WARANTY_SEND = '@@warranty/WARANTY_SEND',
    UPD_WARANTY_SEND = '@@warranty/UPD_WARANTY_SEND',
    ADD_DEVICETYPE = '@@warranty/ADD_DEVICETYPE',
    WARANTY_RETURN = '@@warranty/WARANTY_RETURN',
    UPD_WARANTY_RETURN = '@@warranty/UPD_WARANTY_RETURN',
    WARANTY_HANDOVER = '@@warranty/WARANTY_HANDOVER',
    UPD_WARANTY_HANDOVER = '@@warranty/UPD_WARANTY_HANDOVER',
    ADD_WARRANTYSTATUS = '@@warranty/ADD_WARRANTYSTATUS',
    RESET = '@@warranty/RESET',
}