import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// apicore
import { APICore } from '../../helpers/api/apiCore';

// helpers
import {
    addSupplier as addApi,
    updaSupplier as updApi,
    supplierGroupApi as venGroupApi,multiSupplier_add,
multiSupplier_upd,openVenBalances
} from '../../helpers';

// actions
import { supplierApiResponseSuccess, supplierApiResponseError } from './actions';

// constants
import { SupplierActionTypes } from './constants';

type SupplierData = {
    payload: {
         suppliername: string;urdu_suppliername: string; address1:string; city:string; country:string; firstname:string; middlename:string; lastname:string; phone1: string; phone1comment: string; phone2: string; cellphone: string; phone2comment: string;  email1: string; email1comment: string; email2: string; email2comment: string;   supplier_group_id:string;  purchasecategoryid:string; paytermsid:string;   notes:string; cnic:string;cellphonecode
         :string;phone1code:string;
    };
    type: string;
};
type SupplierUpdData = {
    payload: {
        id:string;  suppliername: string;urdu_suppliername: string; address1:string; city:string; country:string; firstname:string; middlename:string; lastname:string; phone1: string; phone1comment: string; phone2: string; cellphone: string; phone2comment: string;  email1: string; email1comment: string; email2: string; email2comment: string;   supplier_group_id:string;  purchasecategoryid:string; paytermsid:string;   notes:string; cnic:string; cellphonecode
        :string;phone1code:string;
    };
    type: string;
};
type SupplierGroupData = {
    payload: {
        group_name:string;  
    };
    type: string;
};
type mutliType = {
    payload: {data_array:object};
    type: object;
};
type mutliUPD = {
    payload: {data_array:object;id:object};
    type: object;
};
type balanceType = {
    payload: {
        entrydate: string;rowisedata: Object;
    };
    type: string;
};
function* add({ payload: { suppliername, urdu_suppliername, address1, city, country, firstname, middlename, lastname, phone1, phone1comment, phone2, cellphone, phone2comment, email1, email1comment, email2, email2comment, supplier_group_id, purchasecategoryid, paytermsid, notes, cnic,cellphonecode,phone1code } }: SupplierData): SagaIterator {
    try {
        const response = yield call(addApi, { suppliername, urdu_suppliername, address1, city, country, firstname, middlename, lastname, phone1, phone1comment, phone2, cellphone, phone2comment, email1, email1comment, email2, email2comment, supplier_group_id, purchasecategoryid, paytermsid, notes, cnic ,cellphonecode,phone1code});
        const item = response.data;
        yield put(supplierApiResponseSuccess(SupplierActionTypes.ADD_SUPPLIER, item));
    } catch (error: any) {
        yield put(supplierApiResponseError(SupplierActionTypes.ADD_SUPPLIER, error));
            }
}
function* upd({ payload: {id, suppliername,urdu_suppliername, address1, city, country, firstname, middlename, lastname, phone1, phone1comment, phone2,cellphone, phone2comment,  email1, email1comment, email2, email2comment,   supplier_group_id,  purchasecategoryid, paytermsid,   notes, cnic,cellphonecode,phone1code } }: SupplierUpdData): SagaIterator {
    try {
        const response = yield call(updApi, {id, suppliername,urdu_suppliername, address1, city, country, firstname, middlename, lastname, phone1, phone1comment, phone2,cellphone, phone2comment,  email1, email1comment, email2, email2comment,   supplier_group_id,  purchasecategoryid, paytermsid,   notes, cnic ,cellphonecode,phone1code});
        const item = response.data;
        yield put(supplierApiResponseSuccess(SupplierActionTypes.UPD_SUPPLIER, item));
    } catch (error: any) {
        yield put(supplierApiResponseError(SupplierActionTypes.UPD_SUPPLIER, error));
            }
}
function* supplierGroup({ payload: { group_name,  } }: SupplierGroupData): SagaIterator {
    try {
        const response = yield call(venGroupApi, {group_name,  });
        const item = response.data;
        yield put(supplierApiResponseSuccess(SupplierActionTypes.VEN_GROUP, item));
    } catch (error: any) {
        yield put(supplierApiResponseError(SupplierActionTypes.VEN_GROUP, error));
            }
}
function* supplierMultipleAdd({ payload: { data_array,  } }: mutliType): SagaIterator {
    try {
        const response = yield call(multiSupplier_add, {data_array,  });
        const item = response.data;
        yield put(supplierApiResponseSuccess(SupplierActionTypes.ADD_MULTIPLE_SUPPLIER, item));
    } catch (error: any) {
        yield put(supplierApiResponseError(SupplierActionTypes.ADD_MULTIPLE_SUPPLIER, error));
            }
}
function* supplierMultipleUpd({ payload: { data_array,id,  } }: mutliUPD): SagaIterator {
    try {
        const response = yield call(multiSupplier_upd, {data_array,id});
        const item = response.data;
        yield put(supplierApiResponseSuccess(SupplierActionTypes.UPD_MULTIPLE_SUPPLIER, item));
    } catch (error: any) {
        yield put(supplierApiResponseError(SupplierActionTypes.UPD_MULTIPLE_SUPPLIER, error));
            }
}

  function* addVenBalance ({payload:{entrydate,rowisedata}}:balanceType):  SagaIterator{
    try{
        const response = yield call(openVenBalances,{entrydate,rowisedata})
        const balance = response.data;
        yield put(supplierApiResponseSuccess(SupplierActionTypes.OPEN_VEN_BALANCE,balance))
    }catch(error: any) {
        yield put(supplierApiResponseError(SupplierActionTypes.OPEN_VEN_BALANCE, error)); 
    }
}


export function* watchAdd(): any {
    yield takeEvery(SupplierActionTypes.ADD_SUPPLIER, add);
}
export function* watchUpd(): any {
    yield takeEvery(SupplierActionTypes.UPD_SUPPLIER, upd);
}
export function* watchGroup(): any {
     yield takeEvery(SupplierActionTypes.VEN_GROUP, supplierGroup);
}
export function* watchMultiAdd(): any {
     yield takeEvery(SupplierActionTypes.ADD_MULTIPLE_SUPPLIER, supplierMultipleAdd);
}
export function* watchMultiUPD(): any {
     yield takeEvery(SupplierActionTypes.UPD_MULTIPLE_SUPPLIER, supplierMultipleUpd);
}
export function* watchOpenBalance():any{
    yield takeEvery(SupplierActionTypes.OPEN_VEN_BALANCE,addVenBalance)
}


function* supplierSaga() {
    yield all([fork(watchAdd)]);
    yield all([fork(watchUpd)]);
    yield all([fork(watchGroup)]);
    yield all([fork(watchMultiAdd)]);
    yield all([fork(watchMultiUPD)]);
    yield all([fork(watchOpenBalance)]);
    
}

export default supplierSaga;
