export enum ItmmngtActionTypes {
    
    API_RESPONSE_SUCCESS = '@@itmmngt/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@itmmngt/API_RESPONSE_ERROR',
    ADD_ITEM = '@@itmmngt/ADD_ITEM',
    ADD_ITEMUNIT = '@@itmmngt/ADD_ITEMUNIT',
    ADD_ITEMBRAND = '@@itmmngt/ADD_ITEMBRAND',
    ADD_ITEMVARIETY = '@@itmmngt/ADD_ITEMVARIETY',
    ADD_ITEMTYPE = '@@itmmngt/ADD_ITEMTYPE',
    ADD_ITEMCAT = '@@itmmngt/ADD_ITEMCAT',
    ADD_COLOR = '@@itmmngt/ADD_COLOR',
    ADD_ITEMCODE = '@@itmmngt/ADD_ITEMCODE',
    MULTIPLE_ITEMS = '@@itmmngt/MULTIPLE_ITEMS',
    ADD_OS = '@@itmmngt/ADD_OS',
    UPD_OS = '@@itmmngt/UPD_OS',
    FORM_LOC = '@@itmmngt/FORM_LOC',
    ADD_ITEMSUBCAT = '@@itmmngt/ADD_ITEMSUBCAT',
    MULTIPLE_ITEMS_UPD = '@@itmmngt/MULTIPLE_ITEMS_UPD',
    EDIT_ITEMCAT = '@@itmmngt/EDIT_ITEMCAT',
    EDIT_ITEM = '@@itmmngt/EDIT_ITEM',
    SINGLE_ITEM = '@@itmmngt/SINGLE_ITEM',
    RESET = '@@itmmngt/RESET',
    ADD_ADJUSTMENT = '@@itmmngt/ADD_ADJUSTMENT',
    UPD_ADJUSTMENT = '@@itmmngt/UPD_ADJUSTMENT',
    ADD_TRANSFER = '@@itmmngt/ADD_TRANSFER',
    UPD_TRANSFER = '@@itmmngt/UPD_TRANSFER',
    ADD_STOCKIN = '@@itmmngt/ADD_STOCKIN',
    UPD_STOCKIN = '@@itmmngt/UPD_STOCKIN',
    ADD_STOCKOUT = '@@itmmngt/ADD_STOCKOUT',
    UPD_STOCKOUT = '@@itmmngt/UPD_STOCKOUT',

    
}