export enum PurchaseActionTypes {
    API_RESPONSE_SUCCESS = '@@sales/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@sales/API_RESPONSE_ERROR',
    ADD_Purchase = '@@sales/ADD_Purchase',
    ADD_PURCHASEINV = '@@sales/ADD_PURCHASEINV',
    UPD_PURCHASEINV = '@@sales/UPD_PURCHASEINV',
    ADD_PURCHASEGRID = '@@sales/ADD_PURCHASEGRID',
    SUPPLIER_PAYMENT = '@@sales/SUPPLIER_PAYMENT',
    UPD_SUPPLIER_PAYMENT = '@@sales/UPD_SUPPLIER_PAYMENT',
    ADD_PURCHASE_RETURN = '@@sales/ADD_PURCHASE_RETURN',
    UPD_PURCHASE_RETURN = '@@sales/UPD_PURCHASE_RETURN',
    RESET = '@@sales/RESET',
}