import { APICore } from './apiCore';

const api = new APICore();

function warrantyReceAPI(params:any) {
  
    const baseUrl = '/save-warranty-received';
    return api.create(`${baseUrl}`, params);
   
}
function fault_API(params:any) {
  
    const baseUrl = '/add-fault';
    return api.create(`${baseUrl}`, params);
   
}
function devicetype_API(params:any) {
    const baseUrl = '/add-devicetype';
    return api.create(`${baseUrl}`, params);
   
}

function warrantystatus_API(params:any) {
    const baseUrl = '/add-warrantystatus';
    return api.create(`${baseUrl}`, params);
   
}

function UPD_warrantyReceAPI(params:any) {
  
    const baseUrl = '/update-warranty-received/'+params.id;
    return api.update(`${baseUrl}`, params);
   
}
function warrantySendAPI(params:any) {
  
    const baseUrl = '/save-warranty-send';
    return api.create(`${baseUrl}`, params);
   
}
function UPD_warrantySendAPI(params:any) {
  
    const baseUrl = '/update-warranty-send/'+params.id;
    return api.update(`${baseUrl}`, params);
   
}
function warrantyReturnAPI(params:any) {
    const baseUrl = '/save-warranty-return';
    return api.create(`${baseUrl}`, params);
   
}
function UPD_warrantyReturnAPI(params:any) {
    const baseUrl = '/update-warranty-return/'+params.id;
    return api.update(`${baseUrl}`, params);
   
}
function warrantyHandoverAPI(params:any) {
    const baseUrl = '/save-warranty-handover';
    return api.create(`${baseUrl}`, params);
   
}
function UPD_warrantyHandoverAPI(params:any) {
    const baseUrl = '/update-warranty-handover/'+params.id;
    return api.update(`${baseUrl}`, params);
   
}



export {warrantyReturnAPI,
  UPD_warrantyReturnAPI,
  warrantyHandoverAPI,
  UPD_warrantyHandoverAPI,devicetype_API,warrantySendAPI,UPD_warrantySendAPI,warrantyReceAPI,UPD_warrantyReceAPI,fault_API,warrantystatus_API};
